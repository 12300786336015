import React, { Component, useState } from "react";
import { Container, Row, Col, Form, InputGroup, DropdownButton } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { apiCall } from '../../helpers/api'
import { getFilter, setFilter } from '../../helpers/filters'
import { useImageContext } from "../../hooks/useImageContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getSmallNumber } from "../../helpers/stats";
import { getDifference } from '../../helpers/dates'
import { getImageUrl } from "../../helpers/images";
import { Link } from "react-router-dom";
import { getIndustries, getCountries } from "../../helpers/dropdowns";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import NoAccess from "../../images/no-access.png";
import searchBlue from './../../images/search-blue.svg'
import theRoom from './../../images/theRoom.svg';
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";

class InfluencerCampaignsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      campaigns: [],
      paging: {},
      loadingMore: false,
      selectedCampaign: null,
      scrollTop: 0,
      autoShowingExpired: false
    };
    if (getFilter("campaign")) {
      this.props.setFilters(JSON.parse(getFilter("campaign")))
    }
    var browsewallState = JSON.parse(sessionStorage.getItem("browsewallState"));
    if (browsewallState) {
      this.state = browsewallState
      sessionStorage.removeItem("browsewallState")
    } else {
      this.getCampaigns()
    }
  }

  componentWillUnmount() {
    sessionStorage.setItem("browsewallState", JSON.stringify(this.state));
  }

  async getCampaigns() {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('limit', 25);
    urlParams.set('active', true);
    urlParams.set('live', true);
    var response = await apiCall(`influencerCampaigns?${urlParams.toString()}`,{ "method" : "GET" });
    if (response["success"]) {
      this.setState({
        campaigns : response["success"]["campaigns"],
        paging: response["success"]["_paging"]
      });
      if (response["success"]["campaigns"].length == 0 && this.state.autoShowingExpired == false){
        this.setState({
          autoShowingExpired: true
        })
        this.handleFilterUpdate(true,false,"expired")
        return
      }
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.setState({
      isLoaded : true
    });
  }

  restoreScroll(e) {
    document.querySelector(".fullscreenWithTopBar").scrollTo(0,this.state.scrollTop)
  }

  pageOnScroll(e,state){
    const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 10 & e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
    if (bottom && state.campaigns.length < state.paging.total && !state.loadingMore) {
      this.getMoreCampaigns(state.campaigns.length)
    }
    this.state.scrollTop = e.target.scrollTop
  }

  async getMoreCampaigns(skip) {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('limit', 25);
    urlParams.set('active', true);
    urlParams.set('live', true);
    urlParams.set('skip', skip);
    this.setState({
      loadingMore : true
    });
    var existingCampaigns = this.state.campaigns;
    var response = await apiCall(`campaigns?`+urlParams.toString(),{ "method" : "GET" });
    if (response["success"]) {
      existingCampaigns.push(...response["success"]["campaigns"])
      this.setState({
        campaigns : existingCampaigns,
        paging: response["success"]["_paging"]
      });
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.setState({
      loadingMore : false
    });
  }

  CampaignItem(campaign){
    return (
      <div className="mb-4">
        <Card className="shadow h-100" onLoad={campaign.id == campaign.class.state.selectedCampaign ? (event) => { campaign.class.restoreScroll(event) } : null }>
          <Link className="position-relative d-block p-3" to={"/InfluencerRequest?id="+campaign.id} title={campaign.influencerName} onClick={() => { campaign.class.state.selectedCampaign = campaign.id }}>
            <Card.Img className="cardImage rounded" src={getImageUrl(campaign.class.props.providers,campaign.images[0],["w_500","c_scale"])} />
            {!getDifference(campaign.endDate).future && <span className="overlayExpired">Expired</span>}  
            <div className="overlayType" title={campaign.category}>{campaign.category}</div> 
          </Link>
          <Card.Body className="pt-0">
            <div className="text-center px-3 mb-2">
              <img src={getImageUrl(campaign.class.props.providers,campaign.influencerIcon)} width="75%" height="50px" className="object-fit-contain" alt={campaign.influencerName} />
            </div>
            <h6 className="text-center px-4">
              <Link to={"/InfluencerRequest?id="+campaign.id} title={campaign.name} className="wallInfo" onClick={() => { campaign.class.state.selectedCampaign = campaign.id }}>{campaign.name}</Link>
            </h6>
          </Card.Body>
        </Card>
      </div>
    );}

  handleFilterUpdate(value,type,delay=0) {
    var filters = this.props.filters;
    if (type == "search") {
      filters.search = value.target.value
    }
    else if (type == "sortBy") {
      filters.sortBy = value.target.value.trim();
    }
    else if (type == "expired") {
      filters.expired = value
    }
    else if (["categories","countries"].includes(type)) {
      filters[type] = value.map(item => item.value)
    }
    else if (type == "genders"){
      if (value.target.checked) { filters.genders.push(value.target.id) }
      else { filters.genders = filters.genders.filter(function(gender) { return gender != value.target.id })}
    }
    else if (type == "followers"){
      filters.followers = value
    }
    this.props.setFilters(Object.assign({}, this.props.filters, filters));
    setFilter(filters,"campaign")
    if (delay) {
      if (this.props.filterTimer) {
        clearTimeout(this.props.filterTimer)
      }
      this.props.setFilterTimer(setTimeout(() => 
      this.getCampaigns(), delay))
    } else {
      this.getCampaigns()
    }
  }

    reset = () => {
      var defaultValue = {"sortBy":"Creation Date","order":"ASC","groupBy":"","expired":false,"search":"","categories":[],"countries":[],"genders":[],"followers":0}
      this.props.setFilters(defaultValue); 
      setFilter(defaultValue,"campaign")
      this.setState({
        autoShowingExpired: false
      })
      this.getCampaigns()
    }

  render() {
      const { error, isLoaded, campaigns, paging } = this.state;
      if (error) {
        return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5 bg-secondary"><img src={NoAccess} className="noAccessImg my-5 rounded-xl shadow" /><div>Error: {error}</div></div>;
      } else if (!isLoaded) {
        return (             
        <div className="bg-page homePage">
          <Topbar />
          <div className="pageName">Influencer Requests</div>
          <Container className="fullscreenWithTopBar px-0 bg-primary" fluid>
            <FullPageSpinner />; 
          </Container>
          <Footer />
        </div>
          )
      } else {
        var rows = [];
        for (var i = 0; i < campaigns.length; i += 5) {
          rows.push(i);
        }
        return (
          <div className="bg-page homePage">
            <div className="pageName">Home</div>
            <Container className="px-0" fluid onScroll={(e) => this.pageOnScroll(e,this.state)}>
              <Container className="fullscreenWithTopBar px-0 bg-primary" fluid onScroll={(e) => this.pageOnScroll(e,this.state)}>                
                <div className='w-100 text-center d-block d-md-none py-4 my-4'>
                  <img src={theRoom} height="50px" />
                </div>
                <Topbar />
                <Container className="homePageFilters">
                  <Row className="pe-xl-0 justify-content-center">
                    <Col xl={12} xxl={10} className="bg-secondary shadow-sm rounded-bottom-xl d-block d-md-flex align-items-center justify-content-between p-3 mb-1">
                      <div className="d-block d-md-flex align-items-center">
                        <div className="form-group me-md-3 mb-3 mb-md-0">
                          <DropdownButton size="sm" className="customHeaderMultiSelect" variant="light" title={this.props.filters.categories.length == 0 ? "All Categories" : `${this.props.filters.categories.length} Categor${this.props.filters.categories.length == 1 ? "y" : "ies"}`}>
                            <Select
                              placeholder="Category"
                              closeMenuOnSelect={false}
                              components={this.props.animatedComponents}
                              defaultValue={[]}
                              isMulti
                              menuIsOpen
                              options={this.props.industries} 
                              id="categories" 
                              onChange={ (choice) => this.handleFilterUpdate(choice,"categories") } 
                            />
                          </DropdownButton>
                        </div>
                        <div className="form-group me-md-3 mb-3 mb-md-0">
                          <DropdownButton size="sm" className="customHeaderMultiSelect" variant="light" title={this.props.filters.categories.length == 0 ? "All Countries" : `${this.props.filters.categories.length} Countr${this.props.filters.categories.length == 1 ? "y" : "ies"}`}>
                            <Select
                              placeholder="Country"
                              closeMenuOnSelect={false}
                              components={this.props.animatedComponents}
                              defaultValue={[]}
                              isMulti
                              menuIsOpen
                              options={this.props.countries} 
                              id="countries" 
                              onChange={ (choice) => this.handleFilterUpdate(choice,"countries") } 
                            />
                          </DropdownButton>
                        </div>
                        <div className="form-group me-md-3 mb-3 mb-md-0">
                          <DropdownButton size="sm" variant="light" title="Gender">
                            <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                              <input type="checkbox" className="form-check-input me-2 mb-2" id="Male" checked={this.props.filters.genders.includes("Male")} onChange={(e) => this.handleFilterUpdate(e,"genders")} />
                              <label title="" className="form-check-label">Male</label>
                            </div>
                            <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                              <input type="checkbox" className="form-check-input me-2 mb-2" id="Female" checked={this.props.filters.genders.includes("Female")} onChange={(e) => this.handleFilterUpdate(e,"genders")} />
                              <label title="" className="form-check-label">Female</label>
                            </div>
                            <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                              <input type="checkbox" className="form-check-input me-2 mb-2" id="Other" checked={this.props.filters.genders.includes("Other")} onChange={(e) => this.handleFilterUpdate(e,"genders")} />
                              <label title="" className="form-check-label">Other</label>
                            </div>
                          </DropdownButton>
                        </div>
                        <div className="form-group me-md-3 mb-3 mb-md-0">
                          <DropdownButton size="sm" variant="light" title="Followers">
                          <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                            <input type="range" id="followers" className="mx-2" min={0} max={1000000} step={10000} value={this.props.filters.followers} onChange={e => this.handleFilterUpdate(e.target.value,"followers")} />  {getSmallNumber(parseInt(this.props.filters.followers),0)}
                          </div>
                          </DropdownButton>
                        </div>
                        <div className="text-nowrap me-md-2 tragedyRegularFont fs-5">Sort by</div>
                        <Form.Select className="me-md-3 mb-3 mb-md-0 form-select-sm w-100 w-md-auto" value={this.props.filters.sortBy} onChange={e => this.handleFilterUpdate(e,"sortBy")}>
                          <option>Creation Date</option>
                          {/* <option>Popularity</option> */}
                          <option>A - Z</option>
                          <option>Z - A</option>
                        </Form.Select>
                        <button className="btn btn-sm tragedyItalicFontButton text-nowrap" onClick={() => this.handleFilterUpdate(!this.props.filters.expired,"expired") }>{this.props.filters.expired ? "Hide" : "Show"} Expired</button>
                        <button className="btn btn-sm tragedyItalicFontButton ms-2" onClick={() => this.reset() }>Reset</button>
                      </div>
                      <div className="d-block d-md-flex align-items-center">
                        <div className="form-group ms-md-3 mt-4 mt-md-0">
                          <InputGroup size="sm">
                            <Form.Control placeholder="Search" className="border-0" aria-label="Search" value={this.props.filters.search} onChange={(e) => this.handleFilterUpdate(e,"search",500)} />
                            <InputGroup.Text id="basic-addon2" className="bg-white border-0">
                              <a><img src={searchBlue} /></a>
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <Container className="px-0 cardScrollView pb-5 mt-3 mt-md-5">
                  {this.state.autoShowingExpired && (
                      <div className="text-center py-4">
                        <p className="text-secondary small mb-0 fs-5">It looks like we don't have any live campaigns at the moment. {campaigns.length > 0 && "Have a look at some of our previous campaigns below:"}</p>
                      </div>
                  )}
                  <Row className="row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mx-0">
                    {campaigns.map(campaign => <this.CampaignItem key={campaign._id} name={campaign.name} endDate={campaign.endDate} images={campaign.images} class={this} influencerIcon={campaign.influencerIcon} id={campaign._id} influencerName={campaign.influencerName} offering={campaign.offering} category={campaign.categories[0]} />)}
                  </Row>
                  {campaigns.length > 0 ? (
                  <div className="text-center py-5 reachedBottom">
                    {campaigns.length >= paging.total ? <p className="text-secondary mb-0 fs-5">You've reached the bottom of the list!</p> : <span className="spinner-border text-secondary" role="status" aria-hidden="true"></span>}
                  </div>    
                  ) : (
                    <>
                    {this.state.autoShowingExpired == false && (
                      <div className="text-center mt-5 mb-4 pt-5">
                      <p className="text-secondary mb-0 fs-5">It looks like we don't have any live campaigns at the moment. If you'd like to see what was available before, toggle expired campaigns on</p>
                      </div>
                    )}
                    </>  
                    )}
                </Container>
              </Container>
            </Container>
            <Footer />
          </div>
        )
      }
    }
  }

export default function InfluencerCampaigns() {
  const { providers } = useImageContext();
  const animatedComponents = makeAnimated();
  const [ filterTimer, setFilterTimer ] = useState(null); 
  const industries = getIndustries()
  const countries = getCountries()
  const [ filters, setFilters ] = useState({"sortBy":"Creation Date","order":"ASC","groupBy":"","expired":false,"search":"","categories":[],"countries":[],"genders":[],"followers":0})
  return (
    <>
      <InfluencerCampaignsView providers={providers} filters={filters} setFilters={setFilters} filterTimer={filterTimer} setFilterTimer={setFilterTimer} animatedComponents={animatedComponents} industries={industries} countries={countries} />
    </>
  )
}
  