import React, { Component } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { Container, Row, Col, Modal, Form, ToggleButton, ToggleButtonGroup, InputGroup } from 'react-bootstrap';
import { apiCall } from './../../helpers/api'
import { ImageUploadPreviewComponent, uploadNewImages } from "../../components/images/ImageUpload";
import { getImageUrl } from "../../helpers/images";
import { useImageContext } from "../../hooks/useImageContext";
import { datePickerToEpoch } from "../../helpers/dates";
import AlertModal from "../../components/alerts/alert";
import { validateFile } from "../../helpers/files";
import { useAuthContext } from "../../hooks/useAuthContext";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './InfluencerCampaigns.css'

class CreateCampaignView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignData: {"name":"","images":[],"categories":[],"lookingFor":"","whoami":"","additionalDetails":"","media":[],"startDate":datePickerToEpoch(new Date().toISOString().slice(0,16)),"endDate":datePickerToEpoch(new Date(new Date().getTime() + (86400*30000)).toISOString().slice(0,16))},
            counters: {"name":0, "lookingFor":0, "whoami":0,"additionalDetails":0},
            loaders: {"campaign":false, "images":false},
            modals: {"whoami":false, "lookingFor":false},
            imageChanged: false,
            alert: {"display":"toast","visible":false,"title":"","content":""}
        }
    }

    componentDidMount(){
        if (this.props.campaignID){
            this.getCampaign()
        }
    }

    async getCampaign() {
        var tempLoader = structuredClone(this.state.loaders)
        tempLoader.campaign = true
        this.setState({loaders:tempLoader})
        var response = await apiCall(`influencerCampaign?id=${this.props.campaignID}`,{ "method" : "GET" });
        if (response["success"]) {
            var responseData = response["success"]["campaign"]
            var images = await this.loadExistingImages(responseData["images"])
            var media = await this.loadExistingImages(responseData["media"])
            this.setState({
                campaignData : {
                    "name":responseData["name"],
                    "images":images,
                    "media":media,
                    "categories":responseData["categories"],
                    "lookingFor":responseData["lookingFor"],
                    "whoami":responseData["whoami"],
                    "additionalDetails":responseData["additionalDetails"],
                    "startDate":responseData["startDate"],
                    "endDate":responseData["endDate"]
                }
            });
        }
        else {
          this.setState({
              error: response["failure"]["error"]
        });
        }
        tempLoader.campaign = false
        setTimeout(this.setState({loaders:tempLoader}),0)
      }

    handleChange(field,value){
        var tempCampaignData = structuredClone(this.state.campaignData);
        var tempCounters = structuredClone(this.state.counters);
        tempCampaignData[field] = value;
        if (field in tempCounters){
            tempCounters[field] = value.length
        }
        this.setState({campaignData:tempCampaignData,counters:tempCounters})
    }

    handleImages(images){
        var tempCampaignData = structuredClone(this.state.campaignData);
        tempCampaignData.images = images;
        this.setState({campaignData:tempCampaignData})
    }

    handleModals(modal,show=true){
        var tempState = structuredClone(this.state.modals); 
        tempState[modal] = show; 
        this.setState({modals:tempState})
    }

    async loadExistingImages(images){
        var newImages = [];
        for (const image of images){
            const file = {"url":getImageUrl(this.props.providers,image),"data":image.id,"new":false,"original":image}
            newImages.push(file);
        }
        return newImages
    }

    async saveChanges(draft=true){
        var campaignData = structuredClone(this.state.campaignData)
        campaignData.images = await uploadNewImages(campaignData.images)
        campaignData.media = await uploadNewImages(campaignData.media)
        if (this.props.campaignID){
            campaignData.campaignID = this.props.campaignID
        }
        if (draft){
            var response = await apiCall('influencerCampaign',{ "method" : this.props.campaignID ? "PUT" : "POST", "data" : campaignData });
            if (response["success"]){
                this.setState({alert: {"display":"toast","visible":true,"title":"Campaign Draft Saved","content":"Campaign Saved as Draft","className":"bg-success text-white"}})
                if (!this.props.campaignID){
                    window.location.href=`/InfluencerRequest/Create?id=${response["success"]["campaign_id"]}`;
                }
                this.getCampaign()
            }
            else if (response["failure"]){
                this.setState({alert: {"display":"toast","visible":true,"title":"Error Saving Campaign","content":response["failure"]["error"],"className":"bg-danger text-white"}})
              }
        }
        else {
            var response = await apiCall('influencerCampaign/publish',{ "method" : "PUT", "data" : campaignData });
            if (response["success"]){
                return this.props.navigate("/MyRequests")
            }
            else if (response["failure"]){
                this.setState({alert: {"display":"toast","visible":true,"title":"Error Publishing Campaign","content":response["failure"]["error"],"className":"bg-danger text-white"}})
              }
        }
        var tempCampaignData = structuredClone(this.state.campaignData);
        tempCampaignData.images = await this.loadExistingImages(tempCampaignData.images);
        this.setState({campaignData:tempCampaignData})
    }

    ToggleButtonGroupCategories() {    
        return (
            <>
            <ToggleButtonGroup type="checkbox" value={this.state.campaignData.categories} onChange={(e) => this.handleChange("categories",[e[e.length-1]])} id="categories">
                <ToggleButton id="tbg-btn-1" className="me-2 mb-2" value="Activities">Activities</ToggleButton>
                <ToggleButton id="tbg-btn-2" className="me-2 mb-2" value="Arts & Culture">Arts & Culture</ToggleButton>
                <ToggleButton id="tbg-btn-3" className="me-2 mb-2" value="Automotive">Automotive</ToggleButton>
                <ToggleButton id="tbg-btn-4" className="me-2 mb-2" value="Beauty, Grooming & Fragrance">Beauty, Grooming & Fragrance</ToggleButton>
                <ToggleButton id="tbg-btn-5" className="me-2 mb-2" value="Books & Magazines">Books & Magazines</ToggleButton>
                <ToggleButton id="tbg-btn-6" className="me-2 mb-2" value="Charity & Nonprofit">Charity & Nonprofit</ToggleButton>
                <ToggleButton id="tbg-btn-7" className="me-2 mb-2" value="Children & Baby">Children & Baby</ToggleButton>
                <ToggleButton id="tbg-btn-8" className="me-2 mb-2" value="Entertainment & Media">Entertainment & Media</ToggleButton>
                <ToggleButton id="tbg-btn-9" className="me-2 mb-2" value="Environment">Environment</ToggleButton>
                <ToggleButton id="tbg-btn-10" className="me-2 mb-2" value="Fashion">Fashion</ToggleButton>
                <ToggleButton id="tbg-btn-11" className="me-2 mb-2" value="Food & Drink">Food & Drink</ToggleButton>
                <ToggleButton id="tbg-btn-12" className="me-2 mb-2" value="Health, Wellness & Personal Care">Health, Wellness & Personal Care</ToggleButton>
                <ToggleButton id="tbg-btn-13" className="me-2 mb-2" value="Holidays & Travel">Holidays & Travel</ToggleButton>
                <ToggleButton id="tbg-btn-14" className="me-2 mb-2" value="Home & Garden">Home & Garden</ToggleButton>
                <ToggleButton id="tbg-btn-15" className="me-2 mb-2" value="Lifestyle">Lifestyle</ToggleButton>
                <ToggleButton id="tbg-btn-16" className="me-2 mb-2" value="Pets">Pets</ToggleButton>
                <ToggleButton id="tbg-btn-17" className="me-2 mb-2" value="Restaurants, Bars & Clubs">Restaurants, Bars & Clubs</ToggleButton>
                <ToggleButton id="tbg-btn-18" className="me-2 mb-2" value="Sport & Fitness">Sport & Fitness</ToggleButton>
                <ToggleButton id="tbg-btn-19" className="me-2 mb-2" value="Technology, Gaming & Electronics">Technology, Gaming & Electronics</ToggleButton>
                <ToggleButton id="tbg-btn-20" className="me-2 mb-2" value="Tickets">Tickets</ToggleButton>
            </ToggleButtonGroup>
            </>
        );
    }

    async attachFiles(newFiles) {
        var tempCampaignData = structuredClone(this.state.campaignData);
        var files = newFiles.target.files;
        var errors = [];
        var fileArray = tempCampaignData.media;
        if (files.length + fileArray.length){
            files = Object.entries(files).slice(0,5-fileArray.length).map(file => file[1]);
        }
        for (var file of files) {
            var [validationResult, validationReason] = validateFile(file)
            var tempFile = {"data":file,"metadata":null,"type":"file","new":true}
            if (validationResult){
                var response = await apiCall(`getUploadSignature?folder=attachments&asset_type=${tempFile.type}`,{ "method" : "GET" });
                if (response["success"]) {
                    tempFile.metadata = response["success"]["metadata"]
                    fileArray.push(tempFile);
                }
            }
            else {
                errors.push(`${file.name} could not be uploaded: ${validationReason}`)
            }
        }
        if (errors.length > 0){
            this.setState({alert: {"display":"toast","visible":true,"title":"Some files failed to upload","content":`${errors.toString()}`,"className":"bg-danger text-white"}})
        }
        this.setState({ campaignData: tempCampaignData })
    }
    
    removeFile(targetFile){
        console.log(targetFile)
        var tempCampaignData = structuredClone(this.state.campaignData);
        tempCampaignData.media = tempCampaignData.media.filter(function(file) {return file.new == targetFile.new ? targetFile.new ? file.metadata.signature != targetFile.metadata.signature : file.original.name != targetFile.original.name : true });
        this.setState({ campaignData: tempCampaignData })
    }

    render(){
        if (this.state.loaders.campaign) {
            return <FullPageSpinner />;
        }
        return (
            <Container>
                <AlertModal modal={this.state.alert} onClose={() => this.setState({alert: {"display":"toast","visible":false,"title":"","content":""}})} />
                <Modal centered show={this.state.modals.whoami} onHide={() => this.handleModals("whoami",false)}>
                    <Modal.Header closeButton className="modal-success">
                    <Modal.Title className='h6'>Tips for writing a great description</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className="border-0 rounded success">
                        <Form className='justify-content-between align-items-center w-100 m-0'>
                            <p className='m-0 ps-2'>Include details about what you're offering to the brand. This could be specific content or a partnership.</p>
                            <br />
                            <p className='m-0 ps-2'>* Add information about your brand; your values, beliefs and what you stand for.</p>
                            <p className='m-0 ps-2'>* Explain how you can help the brand grow using your audience. </p>
                            <p className='m-0 ps-2'>* Bullet points work well for the offer so you can cover off key points</p>
                        </Form>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={this.state.modals.lookingFor} onHide={() => this.handleModals("lookingFor",false)}>
                    <Modal.Header closeButton className="modal-success">
                    <Modal.Title className='h6'>Tips for writing a great description</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className="border-0 rounded success">
                        <Form className='justify-content-between align-items-center w-100 m-0'>
                            <p className='m-0 ps-2'>Keep your description broad. This will encourage more brands to look to work with you.</p>
                            <br />
                            <p className='m-0 ps-2'>* Add information about what sort of brands you have worked with that you'd like to work with again.</p>
                            <p className='m-0 ps-2'>* A description of what you'd like in return, such as particular products or experiences will help brands know if your collaboration will be suitable.</p>
                        </Form>
                    </Modal.Footer>
                </Modal>
                    <Row className="d-flex justify-content-center">
                        <Col sm={12} md={4} lg={4} xl={4} xxl={3}>
                            <div className="d-flex align-items-center justify-content-between mt-4 mb-4 pb-2 pt-4">
                                <h1 className="pageTitle text-navy fw-700" id="details">
                                    Create Opportunity
                                </h1>
                            </div>

                            <div>
                                Create an opportunity for brands to work with you.
                            </div>

                            <hr className="my-4" />

                            <Row>
                                <Col xs={6} sm={6} md={12} lg={6} xl={6} className="mb-3"><button className="btn btn-outline-primary w-100" onClick={() => {this.saveChanges()}}>Save as Draft</button></Col>
                                <Col xs={6} sm={6} md={12} lg={6} xl={6} className="mb-3"><button className="btn btn-primary w-100" disabled={!this.props.campaignID} title={!this.props.campaignID ? "Save the campaign first to publish" : "Publish the campaign"} onClick={() => {this.saveChanges(false)}}>Publish</button></Col>
                                <Col sm={12} className="mb-3"><Link to="/MyRequests" className="btn btn-outline-danger w-100">Discard Changes</Link></Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={8} lg={8} xl={8} xxl={7}>
                            <div className="px-lg-4 pt-md-4 mt-4 px-md-0">
                                <div className="p-4 bg-white shadow rounded-xl">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <h5 className="mb-0">Details</h5>
                                    </div>

                                    <div className="createCampaignForm">

                                        <div className="form-group my-4 position-relative">
                                            <label>Title</label>
                                            <input type="text" id="name" maxLength="50" className="form-control pe-5" onChange={(e) => {this.handleChange("name",e.target.value)}} value={this.state.campaignData.name} />
                                            <span className="position-absolute smaller end-0 top-0 text-muted mt-4 pt-3 me-2">{this.state.counters.name}/50</span>
                                        </div>

                                        <hr className="my-4" />

                                        <div className="form-group mb-4">
                                            <label>Images (up to 5)</label>
                                            <span className="small text-muted d-block">Capture brands's interest with great quality images that show off what you're all about! For example: product photos, publicity shots, previous events etc.</span>
                                            {this.state.loaders.images ? <p>Loading Images...</p> : 
                                                <ImageUploadPreviewComponent images={this.state.campaignData.images} setImages={this.handleImages.bind(this)} setImagesChanged={() => this.setState({imagesChanged: true})}/>
                                            }
                                            <span className="small text-muted d-block">Please only upload the following formats: <b>PNG</b>, <b>JPEG/JPG</b>, <b>WEBP</b> & <b>HEIC</b>.</span>
                                            <span className="small text-muted d-block"><i>Images should be of high enough quality (500x500 pixels minimum) but no larger than 5mb in size.</i></span>
                                        </div>

                                        <hr className="my-4" />

                                        <div className="form-group">
                                            <label>Categories:</label>
                                            <span className="small text-muted d-block mb-3">Please select a category for your campaign</span>                

                                            {this.ToggleButtonGroupCategories()}
                                        </div>

                                        <hr className="my-4" />

                                        <div className="form-group mb-4 position-relative">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="text-nowrap me-5">Who I am</label>
                                                <a className="text-primary small" onClick={e => {e.preventDefault(); this.handleModals("whoami",true)}}><u>Tips on writing a great description</u></a>
                                            </div>
                                            <span className="small text-muted d-block mb-3">Talk about what you do and what you could offer to brands</span>   
                                            
                                            <textarea type="text" rows="6" maxLength="500" className="form-control pe-5" onChange={e => {this.handleChange("whoami",e.target.value)}} id="whoami" value={this.state.campaignData.whoami}></textarea>
                                            <span className="position-absolute smaller end-0 bottom-0 text-muted me-2 mb-1">{this.state.counters.whoami}/500</span>                                
                                        </div>

                                        <hr className="my-4" />

                                        <div className="form-group mb-4 position-relative">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label>What I'm looking for</label>
                                                <a className="text-primary small" onClick={(e) => {e.preventDefault(); this.handleModals("lookingFor",true)}}><u>Tips on writing a great description</u></a>
                                            </div>
                                            <span className="small text-muted d-block mb-3">This is value exchange only.<br />Explain what you're willing to offer to the brand. </span>   
                                            <textarea type="text" rows="6" maxLength="500" className="form-control pe-5" onChange={e => {this.handleChange("lookingFor",e.target.value)}} id="lookingFor" value={this.state.campaignData.lookingFor}></textarea>
                                            <span className="position-absolute smaller end-0 bottom-0 text-muted me-2 mb-1">{this.state.counters.lookingFor}/500</span>                                
                                        </div>

                                        <hr className="my-4" />

                                        <div className="form-group mb-4 position-relative">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="text-nowrap me-5">Additional Details</label>
                                            </div>
                                            <span className="small text-muted d-block mb-3">Add any other details you think might be important.</span>   
                                            
                                            <textarea type="text" rows="6" maxLength="500" className="form-control pe-5" onChange={e => {this.handleChange("additionalDetails",e.target.value)}} id="additionalDetails" value={this.state.campaignData.additionalDetails}></textarea>
                                            <span className="position-absolute smaller end-0 bottom-0 text-muted me-2 mb-1">{this.state.counters.additionalDetails}/500</span>                                
                                        </div>

                                        <hr className="my-4" />

                                        <div className="form-group">
                                            <label>Campaign dates:</label>
                                            <span className="small text-muted d-block mb-3">This is the period that your campaign will be live on the platform for brands to see. By default, the campaign will run for 30 days</span>                

                                            <Row>
                                                <div className="col-12 col-md-4 form-group mb-4">
                                                    <label className="text-muted">Start date</label>
                                                    <input type="datetime-local" className="form-control" onChange={(e) => {this.handleChange("startDate",datePickerToEpoch(e.target.value))}} value={new Date(this.state.campaignData.startDate*1000).toISOString().slice(0,16)} id="startDate"/>
                                                </div>
                                                <div className="col-12 col-md-4 form-group mb-4">
                                                    <label className="text-muted">End date</label>
                                                    <input type="datetime-local" className="form-control" onChange={(e) => {this.handleChange("endDate",datePickerToEpoch(e.target.value))}} value={new Date(this.state.campaignData.endDate*1000).toISOString().slice(0,16)} id="endDate"/>
                                                </div>
                                            </Row>
                                        </div>

                                        <hr className="my-4" />

                                        <label htmlFor="attachment-upload" className="mb-0 d-flex align-items-center">
                                            <div className="btn btn-outline-primary">Upload media packs {this.state.campaignData.media.length > 0 ? `(${this.state.campaignData.media.length} uploaded)` : ''}</div>
                                            
                                        </label> 
                                        <div className="form-group">
                                            <input type="file" id="attachment-upload" multiple accept=".pdf, .doc, .docx, .rtf, .odt" onChange={(e) => this.attachFiles(e)} variant="default" className="fileUploadBtn d-none" /> 
                                        </div>
                                        <div className="mediaItems">
                                            {this.state.campaignData.media.map(mediaItem => (
                                                <div className="btn btn-outline-danger" onClick={() => this.removeFile(mediaItem)}>Remove {mediaItem.new ? mediaItem.data.name : mediaItem.original.name}</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
            </Container>
        )
    }

}


export default function CreateInfluencerCampaign() {
    let [searchParams] = useSearchParams();
    const navigate = useNavigate()
    var campaignID = null;
    const { data, currentProfile } = useAuthContext();
    const { providers } = useImageContext();
    if (searchParams.get('id')) {
        campaignID = searchParams.get('id');
    }
  
    return (
      <div className="createCampaign">
        <Topbar/>
        <div className="pageName">Create Opportunity</div>
        <Container className="fullscreenWithTopBar px-0" fluid>
                <CreateCampaignView navigate={navigate} data={data} currentProfile={currentProfile} campaignID={campaignID} providers={providers} />
        </Container>
        <Footer />
      </div>
    );
}