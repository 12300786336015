import React, { Component, useState } from "react";
import { Container, Row, Carousel, Badge, Tab, Tabs, Button } from 'react-bootstrap';
import { apiCall } from './../../helpers/api'
import { getDifference } from './../../helpers/dates'
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { useAuthContext } from "../../hooks/useAuthContext";
import AlertModal from "../../components/alerts/alert";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import Spinner from './../../images/tail-spin.svg';
import Clock from "./../../images/clock.svg";
import NoAccess from "../../images/no-access.png";
import event from "./../../images/event.svg";
import { formatText } from "../../helpers/format";

class CampaignView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false
    };
    this.getCampaign(props.id)
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  async getCampaign(id) {
    var response = await apiCall(`influencerCampaign?id=${id}`,{ "method" : "GET" });
    if (response["success"]) {
      this.setState({
        campaign : response["success"]["campaign"]
      });
      this.getStartAndEnd(response["success"]["campaign"])
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.setState({
      isLoaded: true
  });
  }

  getStartAndEnd(campaign){
    this.setState(
      {
        campaignStart : getDifference(campaign["startDate"]),
        campaignEnd : getDifference(campaign["endDate"])
      }
    ) 
  }

  HeaderCarousel(props){
    return (
      <Container className="position-relative px-0 pb-3">      
        <div className="d-none d-md-block px-3">
          <div className="mb-2 mt-3">
            <Link onClick={() => window.history.back()} className="text-white small">&#60; Back</Link>
          </div>
          <h3 className="mb-4 text-white tragedyRegularFont">{props.title}</h3>
          <Row className="imagesBoxView screenshotSize">
            <div className="col-lg-6 col-md-8 col-sm-12 col-12 h-100">
            <img src={getImageUrl(props.class.props.providers,props.images[0],["w_500","c_scale"])} className="w-100 h-100 rounded-xl shadow object-fit-cover" />
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 col-12 h-100">
              <Row className="h-50">
              {props.images.slice(1).map((campaignImage,index) => (
                <div key={campaignImage.id} className="col-6 h-100 col-padding-bottom">
                  <img src={getImageUrl(props.class.props.providers,campaignImage,[props.class.props.isScreenshot ? "w_1000" : "w_500","c_scale"])} className="w-100 h-100 rounded object-fit-cover" />
                </div>
              ))}
              </Row>
            </div>
          </Row>
        </div>
        <div className="d-block d-md-none">
          <div className="blackGradient"></div>      
          <div className="my-4 py-2 px-3 overlayBox">
            <Link className="backArrow me-4" onClick={() => window.history.back()}>
              <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#ffffff"/>
              </svg>
            </Link>
          </div>
          {!props.class.state.campaignEnd.future ? <Badge bg="light" text="dark" className="left"><img src={Clock} className="align-bottom" /> Finished <strong>{props.class.state.campaignEnd.difference} {props.class.state.campaignEnd.unit}</strong> ago</Badge> : (props.class.state.campaignStart.future ? <Badge bg="light" text="dark" className="left"><img src={Clock} className="align-bottom" /> Starts in <strong>{props.class.state.campaignStart.difference} {props.class.state.campaignStart.unit}</strong></Badge> : <Badge bg="light" text="dark" className="left"><img src={Clock} className="align-bottom" /> Ends in <strong>{props.class.state.campaignEnd.difference} {props.class.state.campaignEnd.unit}</strong></Badge>)}      
          <Carousel>
            {props.images.map((campaignImage,index) => (
              <Carousel.Item key={campaignImage.id}>
                <img
                  className="d-block w-100"
                  src={getImageUrl(props.class.props.providers,campaignImage,["w_500","c_scale"])} onError={(event) => event.target.src = Spinner}
                  alt="First slide"
                />
                <Badge bg="light" text="dark" className="right">{index+1}/{props.images.length}</Badge>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Container>
    )
  }

  CampaignPage(props){
    return ( 
      <Container className="p-0" fluid id="screenshotCapture"> 
        <Container className="px-0 mb-5 mb-md-4 mb-lg-0 pb-4 pb-md-2 pb-lg-0 tabViewChange ps-md-3" fluid>
          <props.class.HeaderCarousel class={props.class} images={props.campaign.images} title={props.campaign.name}/>
          <Container fluid className="bg-light rounded-top-xl">
            <Container className="mt-4 pt-4">
              <div className="logoBox mb-3">
                <img src={getImageUrl(props.class.props.providers,props.campaign.influencerIcon)} width="60px" /> {props.campaign.influencerName}
              </div>
              <div className="mb-2">
                <h5 className="mobileView">{props.campaign.name}</h5>
              </div>          
            </Container>
            <Container>
              <Row>
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12 mb-3 mb-md-0">
                <Tabs defaultActiveKey="lookingFor" id="uncontrolled-tab-example" fill >
                    <Tab eventKey="lookingFor" title="Looking For">
                    <div className="p-3 px-md-0">
                        <h6>What I'm looking for</h6>
                        <p className="small text-muted">{formatText(props.class.state.campaign.lookingFor)}</p>
                    </div>
                    </Tab>

                    <Tab eventKey="whoami" title="Who I am">
                    <div className="p-3 px-md-0">
                        <h6>Who I am</h6>
                        <p className="small text-muted pb-3 mb-3">{formatText(props.class.state.campaign.whoami)}</p>
                    </div>
                    </Tab>

                    <Tab eventKey="additionalDetails" title="Additional Details">
                    <div className="p-3 px-md-0">
                        <h6>Additional Details</h6>
                        <p className="small text-muted pb-3 mb-3">{formatText(props.class.state.campaign.additionalDetails)}</p>
                    </div>
                    </Tab>
                </Tabs>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4 col-12 desktopView">
                  <div className="card border-true me-3 bg-secondary">
                    <div className="py-3 d-flex align-items-center card-header">
                      {!props.class.state.campaignEnd.future ? 
                        <Badge bg="warning" text="dark" className="reportBadge">
                          <img src={Clock} className="align-bottom" /> Finished <strong>{props.class.state.campaignEnd.difference} {props.class.state.campaignEnd.unit}</strong> ago
                        </Badge> 
                        : (props.class.state.campaignStart.future ? 
                        <Badge bg="warning" text="dark" className="reportBadge">
                          <img src={Clock} className="align-bottom" /> Starts in <strong>{props.class.state.campaignStart.difference} {props.class.state.campaignStart.unit}</strong>
                        </Badge> 
                        : 
                        <Badge bg="warning" text="dark" className="reportBadge">
                          <img src={Clock} className="align-bottom" /> Ends in <strong>{props.class.state.campaignEnd.difference} {props.class.state.campaignEnd.unit}</strong>
                        </Badge>
                        )} 
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </Container>
        </Container>
      </Container>
    )
  }

  render() {
    const { error, isLoaded, campaign } = this.state;
    if (error) {
      return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5 bg-secondary"><img src={NoAccess} className="noAccessImg my-5 rounded-xl shadow" /><div>Error: {error}</div></div>;
    } else if (!isLoaded) {
      return <FullPageSpinner />;
    } else {
      return (
        <Container className="p-0" fluid>
          <AlertModal modal={this.props.showAlert} onClose={() => this.props.setAlert({"display":"toast","visible":false,"title":"","content":""})} />
          <Container className="p-0" fluid>
            <this.CampaignPage class={this} campaign={campaign} />
          </Container>
        </Container>
      );
    }
  }
}

export default function InfluencerCampaignPreview() {
  let [searchParams] = useSearchParams();
  const campaignID = searchParams.get('id');
  const { providers } = useImageContext();
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const navigate = useNavigate();
  const isScreenshot = searchParams.get('isScreenshot')
  const { data, currentProfile } = useAuthContext();
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});

  return (
    <div className="bg-page adminMenu">
      <Topbar />
      <div className="pageName">Campaign Preview</div>
      <Container className="fullscreenWithTopBar campaignDetail bg-primary px-0" id="screenshotSizer" fluid="fluid">
          <CampaignView id={campaignID} navigate={navigate} setAlert={setAlert} showAlert={showAlert} providers={providers} data={data} currentProfile={currentProfile} isScreenshot={isScreenshot} setIsSubmitting={setIsSubmitting} isSubmitting={isSubmitting} />
      </Container>
      <Footer />
    </div>
  );
}