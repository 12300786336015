import React, { Component, useState } from "react";
import { Container, Row, Col, Form, InputGroup, DropdownButton } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { apiCall } from '../../helpers/api'
import { getFilter, setFilter } from './../../helpers/filters'
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { Link } from "react-router-dom";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getIndustries } from "../../helpers/dropdowns";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import NoAccess from "../../images/no-access.png";
import './Home.css';
import searchBlue from './../../images/search-blue.svg'
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";

class Hidden extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      campaigns: [],
      paging: {},
      loadingMore: false,
      selectedCampaign: null,
      scrollTop: 0,
      view: "brand"
    };
    var browsewallState = JSON.parse(sessionStorage.getItem("browsewallState"));
    if (browsewallState) {
      this.state = browsewallState
      sessionStorage.removeItem("browsewallState")
    } else {
      this.getCampaigns()
    }
  }

  componentWillUnmount() {
    sessionStorage.setItem("browsewallState", JSON.stringify(this.state));
  }

  async getCampaigns(view="brand") {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('limit', 100);
    urlParams.set('active', true);
    urlParams.set('live', true);
    var response = await apiCall(`${view == "brand" ? "campaigns" : "influencerCampaigns"}?${urlParams.toString()}`,{ "method" : "GET" });
    if (response["success"]) {
      this.setState({
        campaigns : response["success"]["campaigns"],
        paging: response["success"]["_paging"]
      });
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.setState({
      isLoaded : true
    });
  }

  async getMoreCampaigns(skip) {
    this.setState({
      loadingMore : true
    });
    var existingCampaigns = this.state.campaigns;
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('limit', 100);
    urlParams.set('active', true);
    urlParams.set('live', true);
    urlParams.set('skip', skip)
    var response = await apiCall(`${this.state.view == "brand" ? "campaigns" : "influencerCampaigns"}?${urlParams.toString()}`,{ "method" : "GET" });
    if (response["success"]) {
      existingCampaigns.push(...response["success"]["campaigns"])
      this.setState({
        campaigns : existingCampaigns,
        paging: response["success"]["_paging"]
      });
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.setState({
      loadingMore : false
    });
  }

  switchView(){
    var newView = this.state.view == "brand" ? "influencer" : "brand"
    this.setState({
      isLoaded : false
    });
    this.getCampaigns(newView);
    this.setState({view: newView}); 
  }

  handleFilterUpdate(value,type,delay=0) {
    var filters = this.props.filters;
    if (type == "search") {
      filters.search = value.target.value
    }
    else if (type == "sortBy") {
      filters.sortBy = value.target.value.trim();
    }
    else if (type == "groupBy") {
      filters.groupBy = value.target.value.trim();
    }
    else if (type == "expired") {
      filters.expired = value
    }
    else if (type == "campaignTypes") {
        filters.campaignTypes = value.target.value;
    }
    else if (type == "categories") {
      filters.categories = value.map(item => item.value)
    }
    this.props.setFilters(Object.assign({}, this.props.filters, filters));
    setFilter(filters,"campaign")
    if (delay) {
      if (this.props.filterTimer) {
        clearTimeout(this.props.filterTimer)
      }
      this.props.setFilterTimer(setTimeout(() => 
      this.getCampaigns(), delay))
    } else {
      this.getCampaigns()
    }
  }

  restoreScroll(e) {
    document.querySelector(".fullscreenWithTopBar").scrollTo(0,this.state.scrollTop)
  }

  CampaignItem(campaign){
    return (
      <div className="mb-4">
        <Card className="shadow w-100 h-100" onLoad={campaign.id == campaign.class.state.selectedCampaign ? (event) => { campaign.class.restoreScroll(event) } : null }>
          <Link className="position-relative d-block p-3" to={`/${campaign.class.state.view == "brand" ? "Campaign" : "InfluencerRequest"}/Preview?id=${campaign.id}`} title={campaign.brandName} onClick={() => { campaign.class.state.selectedCampaign = campaign.id }}>
            <Card.Img className="cardImage rounded" src={getImageUrl(campaign.class.props.providers,campaign.images[0],["w_500","c_scale"])} />

            <div className="overlayType">{(campaign.campaignType == "sampling" && "Sampling") || (campaign.campaignType == "content" && "Content") || (campaign.campaignType == "gifting" && "Gifting") || (campaign.campaignType == "commercial" && "Commercial") || (campaign.campaignType == "cause" && "Cause") || campaign.campaignType}</div>  

          </Link>
          <Card.Body className="pt-0">
            <div className="text-center px-3 mb-2">
              <img src={getImageUrl(campaign.class.props.providers,campaign.brandIcon)} width="75%" height="50px" className="object-fit-contain" alt={campaign.brandName} />
              {/* <div className="cardTitle me-2">
                <Link to={"/Campaign/Preview?id="+campaign.id} title={campaign.brandName} onClick={(e) => { campaign.class.state.selectedCampaign = campaign.id }}>{campaign.brandName}</Link>
              </div>    */}
            </div>
            <h6 className="text-center px-4">
            <Link to={`/${campaign.class.state.view == "brand" ? "Campaign" : "InfluencerRequest"}/Preview?id=${campaign.id}`} title={campaign.name} className="wallInfo" onClick={() => { campaign.class.state.selectedCampaign = campaign.id }}>{campaign.name}</Link>
            </h6>
            {/* <div className="smaller twoLineTruncate text-muted d-none">
                {campaign.offering}
            </div> */}
          </Card.Body>
        </Card>
      </div>
    );}

  pageOnScroll(e,state){
    const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 10 & e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
    if (bottom && state.campaigns.length < state.paging.total && !state.loadingMore) {
      this.getMoreCampaigns(state.campaigns.length)
    }
    this.state.scrollTop = e.target.scrollTop
}    

  reset = () => {
    var defaultValue = {"sortBy":"Creation Date","order":"ASC","groupBy":"","expired":false,"campaignTypes":"all","search":"","categories":[]}
    this.props.setFilters(defaultValue); 
    setFilter(defaultValue,"campaign")
    this.setState({
      autoShowingExpired: false
    })
    this.getCampaigns()
  }

  render() {
      const { error, isLoaded, campaigns, paging } = this.state;
      if (error) {
        return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5 bg-secondary"><img src={NoAccess} className="noAccessImg my-5 rounded-xl shadow" /><div>Error: {error}</div></div>;
      } else if (!isLoaded) {
        return <FullPageSpinner />;
      } else {
        var rows = [];
        for (var i = 0; i < campaigns.length; i += 5) {
          rows.push(i);
        }
        return (
            <div className="bg-page homePage wallPage">
                <Topbar />
                <div className="pageName">Campaign Wall</div>
                <Container className="px-0" fluid onScroll={(e) => this.pageOnScroll(e,this.state)}>
                  <Container className={`fullscreenWithTopBar px-0 ${this.state.view == "brand" ? '' : 'bg-primary'}`} fluid onScroll={(e) => this.pageOnScroll(e,this.state)}>
                    <Container className={`shadow-sm ${this.state.view == "brand" ? 'pageTitleContainer' : 'bg-secondary'}`} fluid>
                      <Container className="px-0">
                        <Row className="py-1">
                          <div className="col-12 d-flex align-items-center justify-content-between">
                            <h1 className="px-3 mb-4 pt-4 pageTitle tragedyRegularFont d-none d-xl-flex">Campaigns</h1>
                            <div className="d-flex align-items-center pe-xl-3 pt-3 pt-xl-0 mt-1 mt-xl-0">
                              <div className="text-nowrap me-2 fs-5 tragedyRegularFont">Categories</div>
                              <div className="form-group me-3">
                                <DropdownButton size="sm" className="customHeaderMultiSelect" variant="light" title={this.props.filters.categories.length == 0 ? "All Categories" : `${this.props.filters.categories.length} Categor${this.props.filters.categories.length == 1 ? "y" : "ies"}`}>
                                  <Select
                                    placeholder="Category"
                                    closeMenuOnSelect={false}
                                    components={this.props.animatedComponents}
                                    defaultValue={[]}
                                    isMulti
                                    menuIsOpen
                                    options={this.props.industries} 
                                    id="categories" 
                                    onChange={ (choice) => this.handleFilterUpdate(choice,"categories") } 
                                  />
                                </DropdownButton>
                              </div>
                              <div className="text-nowrap me-2 fs-5 tragedyRegularFont">Type</div>
                              <Form.Select className="me-3 form-select-sm w-auto rounded" value={this.props.filters.campaignTypes} onChange={e => this.handleFilterUpdate(e,"campaignTypes")}>
                                <option value="all">All</option>
                                <option value="sampling">Sampling</option>
                                <option value="content">Content</option>
                                <option value="gifting">Gifting</option>
                                <option value="commercial">Commercial</option>
                                <option value="cause">Cause</option>
                              </Form.Select>
                              <div className="text-nowrap me-2 fs-5 tragedyRegularFont">Sort by</div>
                              <Form.Select className="me-2 form-select-sm w-auto rounded" value={this.props.filters.sortBy} onChange={e => this.handleFilterUpdate(e,"sortBy")}>
                                <option>Creation Date</option>
                                <option>A - Z</option>
                                <option>Z - A</option>
                              </Form.Select>
                              <button className={`btn btn-sm border me-2 text-nowrap ${this.state.view == "brand" ? 'text-light' : ''}`} onClick={() => this.handleFilterUpdate(!this.props.filters.expired,"expired") }>{this.props.filters.expired ? "Hide" : "Show"} Expired</button>
                              <button className={`btn btn-sm border me-2 text-nowrap ${this.state.view == "brand" ? 'text-light' : ''}`} onClick={() => this.reset() }>Reset</button>
                              <InputGroup size="sm" className="w-auto">
                                <Form.Control placeholder="Search" className="border-0" aria-label="Search" value={this.props.filters.search} onChange={(e) => this.handleFilterUpdate(e,"search",500)} />
                                <InputGroup.Text id="basic-addon2" className="bg-white border-0 me-md-4">
                                  <a><img src={searchBlue} /></a>
                                </InputGroup.Text>
                              </InputGroup>
                                <button className={`btn btn-sm border me-2 text-nowrap ${this.state.view == "brand" ? 'text-light' : ''}`} onClick={() => this.switchView() }>
                                <span className="fill">
                                    <svg width="17" height="17" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M6.14782 1.05392C4.69269 1.05392 3.51302 2.23358 3.51302 3.68871C3.51302 5.14384 4.69269 6.32351 6.14782 6.32351C7.60295 6.32351 8.78262 5.14384 8.78262 3.68871C8.78262 2.23358 7.60295 1.05392 6.14782 1.05392ZM2.45911 3.68871C2.45911 1.65146 4.11057 0 6.14782 0C8.18507 0 9.83653 1.65146 9.83653 3.68871C9.83653 5.72597 8.18507 7.37743 6.14782 7.37743C4.11057 7.37743 2.45911 5.72597 2.45911 3.68871Z" fill="#A7A7A7"/>
                                      <path fillRule="evenodd" clipRule="evenodd" d="M1.08087 11.9445H11.215C10.9513 9.37877 8.78338 7.37743 6.14811 7.37743C3.51283 7.37743 1.34465 9.37871 1.08117 11.9445H1.08087ZM0 12.4715C0 9.07605 2.75253 6.32352 6.14796 6.32352C9.54338 6.32352 12.2959 9.07605 12.2959 12.4715C12.2959 12.7625 12.06 12.9984 11.769 12.9984H0.526959C0.235954 12.9984 0 12.7625 0 12.4715Z" fill="#A7A7A7"/>
                                    </svg>
                                  </span>
                                  <span className='text-small'>{this.state.view == "influencer" ? "Influencer to Brand" : "Brand to Influencer"}</span></button>
                            </div>
                          </div>
                        </Row>
                      </Container>
                    </Container>
                    <Container className="px-0 pt-4">
                    {rows.map((row, index) => (
                      <div key={row}>
                        {/* <div className="d-flex justify-content-between mb-3 px-3">
                        </div> */}
                        <Row className="row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mx-0 justify-content-center">
                          {campaigns.slice(row).map(campaign => <this.CampaignItem key={campaign._id} name={campaign.name} images={campaign.images} class={this} brandIcon={this.state.view == "brand" ? campaign.brandIcon : campaign.influencerIcon} id={campaign._id} brandName={this.state.view == "brand" ? campaign.brandName : campaign.influencerName} offering={campaign.offering} campaignType={this.state.view == "brand" ? campaign.campaignType : campaign.categories[0]} />)}
                        </Row>
                      </div>
                    ))}
                    </Container>
                    <div className="text-center py-5 reachedBottom">
                      {campaigns.length >= paging.total ? <p className="text-muted mb-0 fs-5">You've reached the bottom of the list!</p> : <span className={`spinner-border ${this.state.view == "brand" ? 'text-primary' : 'text-secondary'}`} role="status" aria-hidden="true"></span>}
                    </div>           
                  </Container>
                </Container>
            </div>
        );
      }
    }
  }

export default function (props) {
  const { providers } = useImageContext();
  const animatedComponents = makeAnimated();
  const [ filterTimer, setFilterTimer ] = useState(null); 
  const industries = getIndustries()
  const [ filters, setFilters ] = useState({"sortBy":"Creation Date","order":"ASC","groupBy":"","expired":false,"campaignTypes":"all","search":"","categories":[]})
  return <Hidden providers={providers} filters={filters} setFilters={setFilters} filterTimer={filterTimer} setFilterTimer={setFilterTimer} animatedComponents={animatedComponents} industries={industries} />;
}
  